'use client';

import { redirect } from 'next/navigation';
import { Login } from '@/components/raven/Login';
import { useSiteContext } from '@/context/SiteContext';
import { stubPageType } from '@/utils/analytics/google-analytics';
import { AnalyticsPageView } from '@/components/AnalyticsPageView';
import type { StandaloneLoginProps } from '@themaven-net/phoenix-react';
import { importPhoenixReactComponent } from '@/components/phoenix-react';
import {
	getTargetUrl,
	getAuthConfig,
	getUserCookieConfig,
} from '@/utils/newsletter/auth-utils';

const StandaloneLogin =
	importPhoenixReactComponent<StandaloneLoginProps>('StandaloneLogin');

const LoginComponent = () => {
	const { config, newsletter } = useSiteContext();
	const { channelKey, siteId } = config;
	const taigaApiBase = newsletter.taigaUrl;
	function onComplete() {
		const targetString = window.location.pathname;
		const targetUrl = getTargetUrl(targetString);
		redirect(targetUrl);
	}

	return (
		<StandaloneLogin
			authConfig={getAuthConfig(config)}
			channelKey={channelKey}
			onCompleteFunction={() => onComplete()}
			siteId={siteId}
			taigaApiBase={taigaApiBase}
			userCookieConfig={getUserCookieConfig(config)}
		/>
	);
};

export const LoginPage = () => {
	const { config } = useSiteContext();
	const analyticsData = stubPageType('section');
	const isSsoEnabled = Boolean(
		config?.features2?.flags['use-new-sign-in']?.enabled || false,
	);

	return (
		<>
			<AnalyticsPageView data={analyticsData} />
			{isSsoEnabled ? <Login /> : <LoginComponent />}
		</>
	);
};
