'use client';

import { getAuth } from 'firebase/auth';
import { firebaseApp } from '@/utils/firebase';
import { Button } from '@/components/raven/ui/Button';
import { useSiteContext } from '@/context/SiteContext';
import { TextField } from '@/components/raven/Inputs/TextField';
import { type FC, useMemo, useState, useEffect, type ChangeEvent } from 'react';
import {
	AppleLogo,
	GoogleColored,
	FacebookColored,
	MicrosoftColored,
} from '@themaven-net/raven-assets';
import {
	LoginProvider,
	EmailLinkPhase,
	TARGET_URL_KEY,
	useLoginContext,
	OAuthProviderType,
} from '@/context/LoginContext';

export const LoginComponent = () => {
	const {
		afterCompleteLoginFnRef,
		emailLinkPhase,
		handleAfterFirebaseLogin,
		handleSendEmailLink,
		isLoading,
		message,
		oauthPhase,
		signInOAuth,
	} = useLoginContext();
	const [email, setEmail] = useState<string>('');

	const onContinueClick = async () => {
		// The user must return to the login page that has this component once before returning directly to
		// the page they were originally on.
		const loginUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
		await handleSendEmailLink(email, loginUrl);
	};

	const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	useEffect(() => {
		handleAfterFirebaseLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		afterCompleteLoginFnRef.current = () => {
			const storedTargetUrl = localStorage.getItem(TARGET_URL_KEY);
			localStorage.removeItem(TARGET_URL_KEY);
			const target = new URL(storedTargetUrl || window.location.origin);
			target.hash = `${target.hash ? '&' : '#'}viaLogin`;

			window.location.href = target.href;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) {
		return <div>Loading ...</div>;
	}

	return (
		<div style={{ padding: '32px', textAlign: 'center', width: '500px' }}>
			<div>
				<div>{message}</div>
				{emailLinkPhase === EmailLinkPhase.INPUT && (
					<div>
						<TextField
							label=""
							labelHidden
							name="text"
							onChange={onEmailChange}
							placeholder="Email Address"
						/>
						<div style={{ marginTop: '16px' }}>
							<Button onClick={onContinueClick}>Continue</Button>
						</div>
					</div>
				)}
				{!!oauthPhase && (
					<>
						<div style={{ marginTop: '16px' }}>
							<Button
								onClick={() => {
									signInOAuth(OAuthProviderType.GOOGLE);
								}}
								variation="secondary"
							>
								Sign in with Google <GoogleColored width="16" />
							</Button>
						</div>
						<div style={{ marginTop: '16px' }}>
							<Button
								onClick={() => {
									signInOAuth(OAuthProviderType.FACEBOOK);
								}}
								variation="secondary"
							>
								Sign in with Facebook <FacebookColored width="16" />
							</Button>
						</div>
						<div style={{ marginTop: '16px' }}>
							<Button
								onClick={() => {
									signInOAuth(OAuthProviderType.APPLE);
								}}
								variation="secondary"
							>
								Sign in with Apple <AppleLogo width="16" />
							</Button>
						</div>
						<div style={{ marginTop: '16px' }}>
							<Button
								onClick={() => {
									signInOAuth(OAuthProviderType.MICROSOFT);
								}}
								variation="secondary"
							>
								Sign in with Microsoft <MicrosoftColored width="16" />
							</Button>
						</div>
					</>
				)}
			</div>
			{emailLinkPhase === EmailLinkPhase.SENT && (
				<div>
					<p>
						<strong>Sign-in email sent</strong>
					</p>
					<p>
						A sign-in email with additional instructions was sent to{' '}
						<strong>{email}</strong>. Check your email to complete sign-in.
					</p>
				</div>
			)}
		</div>
	);
};

export const Login: FC = () => {
	const { firebase } = useSiteContext();

	const auth = useMemo(() => {
		return getAuth(firebaseApp(firebase.config));
	}, [firebase]);

	return (
		<LoginProvider auth={auth}>
			<LoginComponent />
		</LoginProvider>
	);
};
